import { Link } from "react-router-dom";

const Rules =() =>{

    return(
        <>
            <div className="container text-center mt-5 pt-5" id="rules">
                <h1 className="pt-5 text-center">Rules</h1>
                <ul className="list-group list-group-flush pb-4">
                    <li className="list-group-item border-0"><i class="fa fa-circle" aria-hidden="true"></i> A team can have a minimum of two members and a maximum of three.</li>
                   
                    <li className="list-group-item border-0"><i class="fa fa-circle" aria-hidden="true"></i> The contest will consist of three rounds, each round is an elimination round.
</li>
                    <li className="list-group-item border-0"><i class="fa fa-circle" aria-hidden="true"></i> All the team members should be from the same institution/organization</li>
                    <li className="list-group-item border-0"><i class="fa fa-circle" aria-hidden="true"></i> A participant can be part of only one team.</li>

                    <li className="list-group-item border-0"><i class="fa fa-circle" aria-hidden="true"></i> All team members should have a D2C account.</li>
                </ul>
      
                    <Link to="/rules"className="btn cbtn mb-4  ">More Rules</Link>
                    <Link to="/terms"className="btn cbtn mb-4  ms-2">Read Terms</Link>
                    <Link to="/contact"className="btn cbtn mb-4  ms-2">Contact</Link>

            </div>
        </>
    )
}
export default Rules;