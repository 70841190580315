
const FAQ = ()=>{

    return(
        <>
        <div className="container " id="faq">
        <p className="h1 text-center mt-4">FAQ</p>
        <div className="accordion" id="accordionExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                How to register?
                </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    For registration please <a href="https://forms.gle/bx98UmBHSpBT2xv59" className="f-link">click here</a> and read the instructions
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Will I get certificates?
                </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Yes, digital certificates will be provided to all participants on completion.
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="heading4">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                I'm a newbie, is there any point in registering?
                </button>
                </h2>
                <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    Hackathons are a great way to learn about new technology and make new friends. We want a diverse audience and beginners are welcome. 
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="heading5">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                Is this hackathon an online event or offline?
                </button>
                </h2>
                <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                CodeVista 2.O is an online event.

                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="heading6">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                Is there any registration fee?
                </button>
                </h2>
                <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Yes. <i class="fa fa-inr" aria-hidden="true"></i>  300 only.
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="heading7">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                What is the selection criteria?
                </button>
                </h2>
                <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    Yes,for more information you need to <a href="#rules" className="f-link">click here</a>
                </div>
                </div>
            </div>
            {/* <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                What is purpose of association?
                </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                These organizations exist for the purpose of bringing students together, helping them acclimate to life at the university, connecting them to other students, as well as alumni, and can help students develop transferable skills.
                </div>
                </div>
            </div> */}
            </div>
                </div>
        </>
    )

}
export default FAQ;