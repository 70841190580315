import logo from "../Assets/cv-logo.png";
import { Link } from "react-router-dom";
const Nav= () =>{

    return(
        <>
        <nav className="navbar sticky-top navbar-expand-lg navbar-light shadow  bg-body rounded  ">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="code vista" className="logo" alt="CodeVista2.O" title="CodeVista2.O" height="54"/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
                    <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/">Home</a>
                    </li>
                    <li className="nav-item">
                    <a  className="nav-link" href="#about">About</a>
                    </li>
                    <li className="nav-item">
                    {/* <Link to="/register" className="nav-link">Register</Link> */}
                    <a href="https://forms.gle/bx98UmBHSpBT2xv59" target="_blank" className="nav-link">Register</a>
                    </li>
                    <li className="nav-item">
                        <a href="#schedule" className="nav-link">Schedule</a>
                    </li>
                    <li className="nav-item">
                        <a href="#rules" className="nav-link">Rules</a>
                    </li>
                    <li className="nav-item">
                        <a href="#prizes" className="nav-link">Prizes</a>
                    </li>
                    <li className="nav-item">
                        <a href="#sponsors" className="nav-link">Sponsors</a>
                    </li>
                    <li className="nav-item">
                        <a href="#faq" className="nav-link">FAQs</a>
                    </li>
                    <li className="nav-item">
                        <Link to="/contact" className="nav-link">Contact</Link>
                    </li>
                </ul>
                
                </div>
            </div>
            </nav>

        </>
    )

}

export default Nav;