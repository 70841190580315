import award from "../Assets/award.png"
const Prize =() =>{

    return(
        <>
            <div className="container prize mt-4" id="prizes">
                <p className="h1 pt-4 mt-4 pb-4 text-center">PRIZES</p>
                <p className="h3 mb-5 text-center">Prizes worth  1,00,000</p>
                <div className="row row-cols-2 row-cols-lg-3 g-2 g-lg-3 d-flex align-items-center justify-content-center">
                    <div data-aos="zoom-in-down" className="col-6">
                        <div className="card card2" >
                        <div className="card-body">
                            {/* <i class="fa fa-trophy" aria-hidden="true"></i>
                             */}
                             <img src={award}  className="img-fluid award-img"  />
                            <p className="h3">1st runner up</p>
                            <h5 className="card-title"><i class="fa fa-inr" aria-hidden="true"></i> 20,000</h5>
                            {/* <h6 className="card-subtitle mb-2 text-muted">1st Price</h6> */}
                        </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-down" className="col-6">
                    <div className="card card1" >
                        <div className="card-body">
                            <i class="fa fa-trophy" aria-hidden="true"></i>
                            <p className="h3">2nd runner up</p>
                            <h5 className="card-title"><i class="fa fa-inr" aria-hidden="true"></i> 15,000</h5>
                            {/* <h6 className="card-subtitle mb-2 text-muted">2nd Price</h6> */}
                        </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-down" className="col-6">
                    <div className="card card1" >
                        <div className="card-body">
                            <i class="fa fa-trophy" aria-hidden="true"></i>
                            <p className="h3">3rd runner up</p>
                            <h5 className="card-title"><i class="fa fa-inr" aria-hidden="true"></i> 12,000</h5>
                            {/* <h6 className="card-subtitle mb-2 text-muted">3rd Price</h6> */}
                        </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-down" className="col-6">
                    <div className="card card1" >
                        <div className="card-body">
                            <i class="fa fa-trophy" aria-hidden="true"></i>
                            <p className="h3">4th Prize</p>
                            <h5 className="card-title"><i class="fa fa-inr" aria-hidden="true"></i> 10,000</h5>
                            {/* <h6 className="card-subtitle mb-2 text-muted">4th Price</h6> */}
                        </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-down" className="col-6">
                    <div className="card card1" >
                        <div className="card-body">
                            <i class="fa fa-trophy" aria-hidden="true"></i>
                            <p className="h3">5th prize</p>
                            <h5 className="card-title"><i class="fa fa-inr" aria-hidden="true"></i> 5000</h5>
                            {/* <h6 className="card-subtitle mb-2 text-muted">5ht Price</h6> */}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Prize;