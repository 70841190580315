
const AboutPage = () =>{


    return(
        <>
        <div>
            
        </div>
        </>
    )
}
export default AboutPage;