import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import logo from "../Assets/logo.png"

const Timeline = () =>{

    return(
        <>
    
        <div className="Timeline" id="schedule">
            <p className="h1 text-center mt-4 pt-5">Schedule</p>
            <VerticalTimeline
            >
            <a href="https://forms.gle/bx98UmBHSpBT2xv59" target="_blank" className="c-a">
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2011 - present"
                iconStyle={{ background: '#70d8d4', color: '#fff' }}
                
            >
                <h3 className="vertical-timeline-element-title">Registrations Start</h3>
                <p>
                You can register for our hackathon using the link provided above.
                </p>
                {/* <button className=" btn " >Register Now </button> */}
            </VerticalTimelineElement>
            </a>

            
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2010 - 2011"
                iconStyle={{ background: '#70d8d4', color: '#fff' }}
                
            >
                <h3 className="vertical-timeline-element-title">Team Confirmation and RSVP</h3>
                <h4 className="vertical-timeline-element-subtitle"></h4>
                <p>
                We will be reviewing participants and confirming their participation.
                </p>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2008 - 2010"
                iconStyle={{ background: '#70d8d4', color: '#fff' }}

            >
                <h3 className="vertical-timeline-element-title">Registration End</h3>
                <p>
                We will be closing registrations.
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2006 - 2008"
                iconStyle={{ background: '#70d8d4', color: '#fff' }}

            >
                <h3 className="vertical-timeline-element-title">Competition Begins</h3>
                <p>
                    Let the HACKATHON begin..!!!
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="April 2013"
                iconStyle={{ background: '#70d8d4', color: '#fff' }}

            >
                <h3 className="vertical-timeline-element-title">Round 1: CODE RIDDLE</h3>
                <p>This will be a 3 Hours contest having problems of and It will be conducted on the D2C Platform.
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="April 2013"
                iconStyle={{ background: '#70d8d4', color: '#fff' }}

            >
                <h3 className="vertical-timeline-element-title">Round 2: TEAM-A-THON</h3>
                <p>
                This will be a 2-3 Hours contest having problems and it will be conducted on the D2C Platform
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="April 2013"
                iconStyle={{ background: '#70d8d4', color: '#fff' }}

            >
                <h3 className="vertical-timeline-element-title">Round 3: FINAL SHOWDOWN</h3>
                <p>
                    This will be a 3 Hours contest where for each team there is a separate bot. it will be conducted on the Riddles.
                </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="April 2013"
                iconStyle={{ background: '#70d8d4', color: '#fff' }}
        
            >
                <h3 className="vertical-timeline-element-title">Competition end</h3>
                <p>
                 The competition would end and we would contact winning teams for further processes.
                </p>
            </VerticalTimelineElement>
           
            </VerticalTimeline>
        </div>
        </>
    )
}
export default Timeline;
