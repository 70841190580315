import termpdf from "./Files/Terms-And-Conditions-CodeVista.pdf"
const Terms = () =>{

    return(
        <>
        <div className="container mt-4">
            <div class="row gx-5 mb-4">
                <div class="col">
                    <p className="h3">Terms and Conditions</p> 
                </div>
                <div class="col-2">
                    <a href={termpdf} className="btn btn-dark">Download</a>
                </div>
            </div>

            Please read the Terms and all applicable Conditions (as defined below) carefully as they form a binding legal agreement between you and CODE VISTA 2.0 Organizers.

            <br></br>
            <br></br>

            Our Contests vary and we may post specific rules for a CODE VISTA 2.0 2.0 on the contest website, which, along with these Terms, become part of your agreement with us with respect to the Contest. If there is a conflict between these Terms and the Rules, then the Rules take precedence.
            <br></br>
            <br></br>

            Any decision or determination of any Contest administrator or judge, including interpretation and applications of the Terms and Rules, is final and binding in all matters relating to CODE VISTA 2.0 2.0
            <br></br>
            <br></br>

            The Terms and Rules include information about how we use your personal information when you create a Contest Profile (as defined below) or participate in a Contest. UNLESS YOU AGREE TO THE TERMS AND APPLICABLE RULES, YOU (1) MUST NOT  PARTICIPATE IN CODE VISTA 2.0 2.0, AND (2) ARE NOT ELIGIBLE TO RECEIVE PRIZES UNDER A CONTEST.

         <br/><br/>   <p className="h4">1. Eligibility:</p>
            <p>1.1 To participate in the Contest:</p>
            <ol>
                <li>Your age should be above 17 years and above</li>
                <li>You must be enrolled in a UGC/PGC program when you register for the Contest.</li>
            </ol>
            <p>1.2 Verifying Eligibility:</p>
            <ol>
                <li>CODE VISTA 2.0 holds all rights to adjudicate on any dispute and verify your eligibility at any stage of the contest.</li>
                <li> By entering the Contest, you agree to produce any proof of eligibility asked by CODE VISTA 2.0 for verification purposes.</li>
                <li> If you fail to provide the proof of eligibility, it will result in your immediate disqualification from the contest and forfeiture of the prizes</li>
            </ol>
        <p>1.3 To participate in the Contest, you must have</p>
        <ol>
            <li>Access to the INTERNET,(Expected internet speed: 10Mbps)</li>
            <li>A Google Account with D2C Platform Login (Preferred college Domain Email Address</li>
            </ol>
            <p>1.4 The communication between CODE VISTA 2.0 Authority and the participating team will be considered only through the means of the COLLEGE website, email communications, and through phone calls, the preferred languages will be in ENGLISH & HINDI
</p>

<br></br><br></br>
<p className="h4">2. Judging Criteria:</p>

<ol>
    <li>All teams will be given the same set of problems to solve.</li>
    <li>Teams will be ranked as per the number of problems solved</li>
    <li>Each problem will have a specified point value. The more difficult the problem, the more points a correct solution will receive.</li>
    <li>Programs will be tested by two independent judges (appointed by the Contest Committee) by running them with a set of test inputs. The output for each test case will be marked as right or wrong using keys prepared before the contest.</li>
    <li>A program will be considered wrong if it fails to work on one or more of the test cases. It will otherwise be considered correct for the purposes of this contest. A program may still be wrong even if it passes all the test input cases. Every effort will be made to design test cases that will minimize the chance of missing an incorrect program. A correct program will be awarded the full points, an incorrect one zero! In the event of a tie, programs will be awarded partial credits based on the number of tests correctly passed.</li>
    <li>The Contest Committee will tally the scores for each team and publish a complete list. The teams with the four highest scores will be declared the winners and will be invited to take part in the trivia quiz round. The Contest Committee's decision in all matters is final.</li>
</ol>
<p className="h6">1. Requirements for the solutions:
</p>
<ul>
    <li>The full program should be contained in a single file.</li>
    <li>If the problem statement doesn't specify the names of input or output, you must read the data from the standard input and write it to the standard output.</li>
    <li>You are forbidden to work with the Net.</li>
    <li>You are forbidden to perform input-output operations except for the opening, closing, reading, and writing files and standard streams given in the problem statements to perform input-output.</li>
    <li>You are forbidden to run other programs and create processes.</li>
    <li>You are forbidden to modify files or directories' permissions in the file system.</li>
    <li>You are forbidden to work with directories other than the current one.</li>
    <li>You are forbidden to work with the operating system registry.</li>
    <li>You are forbidden to create and use GUI elements (windows, dialogs, etc.)</li>
    <li>You are forbidden to work with external devices.</li>
    <li>You are forbidden to perform any other actions that can in any manner destabilize the judging process.</li>
</ul>
<p className="h6">2. After a contestant solves a problem, he submits the solution via the contest system interface and continues working on other problems. After the system receives and judges a solution, it immediately responds by displaying the result of judging the solution.</p>
<p className="h6">3. A contestant can see the results of judging his solutions on the corresponding tab of the contest system. During a contest, a contestant can see the results of only his solutions' judging.
</p>
<br/>
<p className="h4">3. Tiebreaking Stage:</p>
<p>The tiebreaking test will establish the final ranking and is exclusively dedicated to the contestants who obtained the same maximum score. If the contestants also obtain an identical score in the tiebreaking test, the following tie-breaking criterion will consist in the time spent taking the test.</p>

<p className="h4">4. Prizes/ Certificates:</p>
<b>Prizes for the Runner Up:
</b>
<ol>
    <li>Cash Prizes:<br/>
    All the cash prizes will be awarded in Indian Rupees and will be given in the form of cash, cheque, or any other mode of payment.
</li>
<li>Taxes:
<br/>
Participants will be solely responsible for complying with the pertinent tax laws. All the cash prizes will be given after proper deduction of taxes as required by the law.</li>
<li>Forfeiture
You will forfeit your attendance at the final round and/or your prize, and an alternative finalist or winner may be selected, if:
<ul>
<li>You fail to provide a phone number during registration or any other information requested by CODE VISTA 2.0 Team within five (5) days of request.</li>
    <li>You fail to respond to CODE VISTA 2.0 Team’s email notification of your final round eligibility or prize within ten (10) days.</li>
    <li>You fail to follow directions provided by CODE VISTA 2.0 for attending the final round or receiving the prize.</li>
    <li>Your email address is inactive and emails are returned to CODE VISTA 2.0 Team. or</li>
    <li>You fail to pay applicable taxes or timely submit applicable documentation to CODE VISTA 2.0 or the relevant tax authority.</li>
</ul>
</li>
   
  </ol>
    
<p className="h3">5. Disqualification</p>
<ol>
    <li><b>a. At the time of the verification</b>
    <br/>
    CODE VISTA 2.0 Team reserves the right to verify your eligibility and to adjudicate on any dispute at any time. You agree to provide the CODE VISTA 2.0 Team with any proof of eligibility requested by the CODE VISTA 2.0 Team and your refusal or failure to timely provide such proof may result in your disqualification from the Contest.
</li>
<li>You may be disqualified from the Contest, forfeit any prizes you may be eligible to receive, and be prohibited from participating in any future GHRIET Coding Contests if CODE VISTA 2.0 Team reasonably believes that you have attempted to undermine the legitimate operation of the Contest, including.
<ul>
    <li>Violating CODE VISTA 2.0’s Code of Conduct at any stage of the tournament.</li>
    <li>  Providing false information about yourself during registration or concerning your eligibility.
</li>
<li> Breaching or refusing to comply with these Contest Rules;</li>
<li>Threatening or harassing other contestants, GHRIET, or any Organization, including their employees and representatives.
</li>
<li>Tampering or interfering with the administration of the Contest or with the ability of other contestants to participate in the Contest.</li>
<li>Creating multiple accounts.</li>
<li> Submitting code in a coding round that is not original (e.g., is copied from another student’s code</li>
<li>Using language which is lewd, obscene, pornographic, racist, sexist, or otherwise inappropriate to the Contest, or violates any applicable law</li>
<li>Not complying with any of the terms listed here</li>
<li>Getting involved in any malicious activity during any stage of the tournament.</li>
</ul>
</li>
</ol>

        </div>
        </>
    )

}
export default Terms;