import logo from "./logo.png"
import rulepdf from "./Files/Rules-And-Regulations.pdf"
const RulePage = () =>{

    return(
        <>
            <div className="container  pt-4">
                <div class="row gx-5 mb-4">
                    <div class="col-sm">
                        <p className="h4">Rules and regulations</p> 
                    </div>
                    <div class="col-2">
                        <a href={rulepdf} className="btn btn-dark">Download</a>
                    </div>
                </div>

                <p className="h3">1) Format of the Competition</p>
                <p className="h6">Team Formation:</p>
                <ol>
                    <li>A team can have a minimum of two members and a maximum of three.</li>
                    <li>All team members should have a D2C account. (also mentioned in eligibility)</li>
                    <li>All the team members should be from the same institution/organization.</li>
                    <li>A participant can be part of only one team.</li>
                    <li>The contest will consist of three rounds, each round is an elimination round.</li>
                </ol>
                The contest will consist of three rounds, with teams progressing in every round as stated below:
                    
                    <br/>
                    <br/>

                <p className="h4">ROUND 1:  CODE RIDDLE</p>
                <ol>
                    <li>This round will be conducted on the D2C Platform.</li>
                    <li>This will be a 3 Hours contest </li>
                    <li>The round will have up to 10 Questions.</li>
                    <li>The time given to solve the question is 10 minutes for each question.</li>
                    <li>This Round is the elimination round</li>
                    <li>Teams will be ranked as per the number of problems solved</li>
                </ol>

                <br/>
                <br/>
                <p className="h4">ROUND 2:  TEAM-A-THON</p>
                <ol>
                    <li>This round will be conducted on the D2C Platform.</li>
                    <li>This will be a 2-3 Hours contest having problems of MEDIUM LEVEL Difficulty.</li>
                    <li>This round will have around up to 10 Questions.</li>
                    <li>The time given to solve the question is 20 to 25 minutes.</li>
                </ol>

                <br/>
                <br/>

                <p className="h4">ROUND 3: FINAL SHOWDOWN</p>
                <ol>
                    <li>This round will be conducted on the Riddles.</li>
                    <li>This will be a 3 Hours contest where for each team there is a separate bot</li>
                    <li>In this round Participants have to develop an algorithm (bot) to complete a specific task and ranking will be decided based on the efficiency of the algorithm.</li>
                    <li>Here participants bot will compete with each other</li>
                </ol>

        <br/>
        <br/>

        <p className="h3">2) Who can participate ?</p>
        <p className="h5">i. Requirements to participate</p>
        
            To participate in the Contest, you must have
            <ol>
                <li>Access to the INTERNET(Expected internet speed: 10Mbps)</li>
                <li>A Google Account with D2C Platform Login (Preferred college Domain Email Address</li>
            </ol>
            <p className="h5">ii. Proof of Eligibility</p>
            <o>
                <li>Your age must be at least 17 years and above</li>
                <li>You must be enrolled in a UGC when you register for the Contest</li>
            </o>
            <p className="h5">iii. Verifying Eligibility</p>
            <o>
                <li>CODE VISTA holds all rights to adjudicate on any dispute and verify your eligibility at any stage of the contest</li>
                <li>By entering the Contest, you agree to produce any proof of eligibility requested by CODE VISTA for verification purposes</li>
                <li>If you fail to provide proof of eligibility, it will result in your immediate disqualification from the contest</li>
            </o>

            <p className="h5">iv. Communications</p>
            <o>
                <li>The communications between CODE VISTA Authority and the participating team will be considered only through the means of the COLLEGE website, email communications, and through phone calls, the preferred languages will be in ENGLISH & HINDI</li>
                <li>The communications between CODE VISTA Authority and the participating team will be considered only through the means of the COLLEGE website, email communications, and through phone calls, the preferred languages will be in ENGLISH & HINDI</li>
            </o>

            <br/>
            <br/>
            <p className="h3">3) How to Register?</p>
            <ol>
                <li>To take part in the contest, you must have a Dare2Compete Account. If you don’t have one, create your Dare2Compete Account <a className="text-dark" href="https://dare2compete.com/"> here.</a></li>
                <li>You need to have a completed profile (fill up all the mandatory fields if you have not done so already). To complete your profile, you can go to your My accounts page on Dare2Compete.</li>
                <li>You need to register <a className="text-dark" href="https://forms.gle/bx98UmBHSpBT2xv59"> here.</a>, your team for the contest before you can participate. You can register only one team for the contest. Details about team formation: 
                <ul>
                    <li>A team can have two or three members.</li>
                    <li>There is no institution-wise restriction. That is, participants from different organizations/institutes will be allowed to form teams.</li>
                    <li>A participant can be part of only one team.</li>
                </ul>
                </li>
                <li>Google form </li>
            </ol>

<br/>
<br/>

                <p className="h3">4) Disqualification</p>
                <ol>
                    <li>At the time of the verification. CODE VISTA Team reserves the right to verify your eligibility and to adjudicate on any dispute at any time. You agree to provide the CODE VISTA Team with any proof of eligibility requested by the CODE VISTA Team and your refusal or failure to timely provide such proof may result in your disqualification from the Contest.</li>
                    <li>You may be disqualified from the Contest, forfeit any prizes you may be eligible to receive, and be prohibited from participating in any future GHRCEM Coding Contests if CODE VISTA Team reasonably believes that you have attempted to undermine the legitimate operation of the Contest, including:
<ol>
    <li>Violating Code Vista’s Code of Conduct at any stage of the tournament.</li>
    <li> Providing false information about yourself during registration or concerning your eligibility;</li>
    <li>Breaching or refusing to comply with these Contest Rules.</li>
    <li>Threatening or harassing other contestants, GHRCEM, or any Organization, including their employees and representatives.</li>
    <li>Tampering or interfering with the administration of the Contest or with the ability of other contestants to participate in the Contest.</li>
    <li>Creating multiple accounts</li>
    <li>Submitting code in a coding round that is not original (e.g., is copied from another student’s code).</li>
    <li>Using language which is lewd, obscene, pornographic, racist, sexist, or otherwise inappropriate to the Contest, or violates any applicable law.</li>
    <li>Not complying with any of the terms listed here.</li>
    <li>Getting involved in any malicious activity during any stage of the tournament.</li>
</ol>
</li>
                </ol>

                <p className="h3">5) General</p>
                <ol>
                    <li><b>Severability:</b><br/>
                    If any term (or part of a term) of these Contest Rules is invalid, illegal or unenforceable, the rest of the Contest Rules will remain in effect.
                    </li>
                    <li><b>Translations: </b><br/>
    	In the event of any discrepancy between the English version of these Contest Rules and a translated version, the English version will govern.</li>
                </ol>
<br/>
<br/>
            <p className="h3">6) CODE VISTA 2.0’s Code of Conduct</p>
            “Code Vista 2.0” is a playground for computer programmers. And just like any other sport, we too have some basic expectations from our participants. Nothing stringent to worry about. These are just simple and small practices that all of you have been following, or if not, should be following from now on.<br/><br/>
            <p>So, let’s get started:</p>

            <ol>
                <li>You should not have multiple accounts on the D2C Platform and Riddles.io . If you have, you should delete all except one.</li>
                <li> If you encounter any issue during an ongoing contest, post it as a comment on the respective problem page. The problem setter will help you get over it.</li>
                <li>After the contest, all queries related to any specific problem should be posted on the editorial page of the problem on the discussion forum. (LINK)</li>
                <li>You should avoid sharing formulae, logic, or any other significant aspect of your code during a contest. If our plagiarism algorithm finds striking similarities between your code and someone else’s, your account may stand the risk of being suspended. Or your team will be disqualified from the contest.</li>
                <li>If you are taking part in Code VISTA 2.0, you must take good care of your code. Protect it from getting leaked, getting shared, and getting copied by your foes, friends, or just a random peer. It is your responsibility to provide it with the safety it deserves.</li>
                <li>Avoid using any online IDE’s or code-sharing sites like “Pastebin” to share your code. In case you must, the responsibility of protecting your code lies solely with you. If you’re using Ideone or any other online ide, you can read about protecting your code on it here(link). Or you can use our IDE.</li>
                <li> Taking help or using third-party code is not bad. Passing it off as your own is. If you are taking your code from some other source, it is expected that you give due attribution to the source in your code. It is mandatory. The third-party code should have been available publicly before the relevant contest began, and not created during the contest and if questioned, the proof of burden rests on you to prove this beyond a doubt.<br/>
                But in case you have missed giving attribution to a third-party code,  you get caught in our plagiarism check, then you have the option of proving to us that this was indeed a publicly available code.</li>
                <li> Do not ask or discuss any aspect of Code Vista 2.0 's problems during an ongoing contest on any other platform, online or offline. Discussion of strategy should be avoided during the contest and postponed till the end.</li>
                <li>Unless specified otherwise, you are expected to solve and code the problems yourself and not discuss them with others.</li>
                <li>Stay honest with the community, if you come across any wrongdoing, which hampers the decorum of the community on or off it, do report that to us. We will investigate the issue and take appropriate action to stop it.</li>
                <li>You write beautiful codes so why ruin it by obfuscating it? Keep the real beauty of the code intact. Do not obfuscate it.</li>
                <li> Do not try any dishonest means to move up the rank tables 
                    <br/>
                    Not adhering to the above set of guidelines may lead to suspension, permanent ban of your account, and/or reduction in your rating points. You may also lose all claims on prizes or past rewards of any kind.
                </li>
            </ol>

<p className="h3 text-center">Disclaimer</p>
<p>The "Rules" listed herein are applicable to "Code Vista 2.0" and every aspect of it. We urge you to go through these Terms with awareness as they form a legal agreement between you and CODE VISTA. There can be some alterations in the contest, which when they happen may require changes in the existing terms or require inclusion of completely new terms. The changes will become part of the existing terms and your agreement with CODE VISTA with respect to the Contest.</p>
<p>OR
</p>
<p>There can be some alterations in the contest, which may happen to change whenever required in the existing terms or require inclusion of completely new terms. The changes will become part of the existing terms and your agreement with CODE VISTA with respect to the Contest.</p>
<p className="h6"><i>By agreeing to our Rules and Regulations, you also agree to adhere to our Terms of Service.</i></p>

            </div>
        </>
    )
}

export default RulePage;