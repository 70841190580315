import { VFXProvider } from "react-vfx"; 

const HeadText = () =>{

    return(
        <VFXProvider>
                    {/* Render text as image, then apply the shader effect! */}
                    {/* <VFX.VFXSpan shader="rainbow">Hi there!</VFX.VFXSpan> */}

                </VFXProvider>
    )
}
export default HeadText