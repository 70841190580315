import {BrowserRouter as Router, Routes, Route,} from 'react-router-dom';

import './App.css';
import Footer from './Components/footer.component';
import Nav from './Components/nav.component';
import Home from './home.react';
import {useEffect} from "react";
import Aos  from 'aos';
import 'aos/dist/aos.css';
import Register from './Components/register.component';
import AboutPage from './Components/Pages/about.page';
import PageNotFound from './Components/Pages/404.page';
import RulePage from './Components/Pages/rules.page';
import Terms from './Components/Pages/terms.page';
import ContactPage from './Components/Pages/contact.page';


// import RulesPage
function App() {
  useEffect(()=>{
    Aos.init({duration:1000});
},[]);
  return (
    <div className="App">

        <Router>
          <Nav/>
        <Routes>
          <Route path="/" exact element={<Home/>}/>
          <Route path="/register" element={<Register/> }/>
          <Route path="/about-event" element={<AboutPage/> }/>
          <Route path="/rules" element={<RulePage/> }/>
          <Route path="*"  element={<PageNotFound/>} />
          <Route path="/terms"  element={<Terms/>} />
          <Route path="/contact"  element={<ContactPage/>} />
          {/* <Navigate to="/404" /> */}
        </Routes>
        <Footer/>
     </Router>

    </div>
  );
}

export default App;
