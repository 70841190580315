
const Register =()=>{

    return(
        <>
        <div className="d-flex justify-content-center regi">

        <iframe title="Register here" src="https://docs.google.com/forms/d/e/1FAIpQLSeCPBmGBRBw7qZZWlDPubIIanLZ9rqIZlsERlHXT44KN5cs8A/viewform?embedded=true" width="640" height="842" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>

        </>
    );
}
export default Register;