import { Link } from "react-router-dom";
import logo from "../Assets/cv-logo.png";
const Footer = () =>{

    return(
        <div className="text-center footer">
                <img src={logo} className="logo fcom " height="54" />
                <p className="h5 fcom">G H Raisoni Institute Of Engineering & Technology, Pune</p>
                {/* <p className="white">Contact Us</p> */}
                <Link className="btn" to="/contact">Contact Us</Link>
                <div className="row gx-5">
                    <div className="col fcom">
                       <a href="https://github.com/A-C-I-T-E-S" className="link" > <i class="fa fa-github" aria-hidden="true"> </i> </a>
                       <a href="https://linkedin.com/company/a-c-i-t-e-s-ghriet" className="link" >  <i className="fa fa-linkedin" aria-hidden="true"></i></a>
                       <a href="https://www.instagram.com/a.c.i.t.e.s/" className="link" >  <i className="fa fa-instagram" aria-hidden="true"></i></a>
                       <a href="https://twitter.com/a_c_i_t_e_s" className="link" >  <i className="fa fa-twitter" aria-hidden="true"></i></a>
                    </div>
                </div>
                <hr className="white"/>
                <p className='fcom white ' >
                CodeVista | Developed by <a className="c-link" href="https://acites.cwipedia.in">ACITES</a> Team 
        </p>
        </div>
    )
}
export default Footer;