import code from "../Assets/code.gif";
import { Link } from "react-router-dom";
const About = () =>{

    return(
        <>
            <div className="container about text-center pt-5" id="about">
                <div className="row gx-5">
                    <div data-aos="zoom-in" className="col-sm">
                        <img  src={code}   className="img-fluid cimg" />
                    </div>
                    <div ata-aos="zoom-out-up" className="col-sm col-sm1">
                        <p className="display-6">About event</p>
                        <p className="h3">A national level coding competition by RGI Pune</p>
                        <p>
                        CodeVista 2.O is a Coding competition event for college students. CodeVista 2.O will provide you an opportunity to measure capabilities and provide a platform to explore. Join in for RGI Pune's Coding competition that will test your ability to perform under pressure and put skills and knowledge into action to create effective solutions.
                        </p>
                        <p className="h5">
 Cash prizes worth <i class="fa fa-inr" aria-hidden="true"></i> 1,00,000</p>
            {/* <Link to="/rules"className="btn cbtn mb-4">Learn More</Link> */}
            <Link to="/rules"className="btn cbtn mb-4  ">Read Rules</Link>
            <Link to="/terms"className="btn cbtn mb-4  ms-2">Read Terms</Link>
            <a href="https://forms.gle/bx98UmBHSpBT2xv59" className="btn cbtn mb-4 ms-2" >Register Now</a>


                    </div>
                </div>
                </div>

        </>
    )

}
export default About;