import About from "./Components/about.component";
import FAQ from "./Components/faq.component";
import Header from "./Components/header.component";
import Prize from "./Components/prize.component";
import Rules from "./Components/rules.component";
import Sponsor from "./Components/sponsor.component";
import Timeline from "./Components/timeline.component";
import Why from "./Components/why.component";
// import Intruction from "./Components/intruction.component";
const Home =()=>{
    
  


    return(
        <>
            <Header/>
            <About/>
            <Why/>
            <Timeline/>
            <Rules/>
            <Prize/>
            <Sponsor/>
            <FAQ/>
        </>
    )
}
export default Home;