

const Why = () =>{
    return (
        <>
        <div className="container mb-4 border why-c rounded-2 border-info pt-4 pb-4">
        <p className="h1 text-center">Why CodeVista 2.O?</p>
            <div className="row g-2">
                <div className="col-6">
                    <div className="card text-center border-0 bg-transparent">
                    <div className="card-body">
                        <h5 className="card-title">Stunning Experience</h5>
                        <p className="card-text mb-4">Meet students from all over India and make new connections</p>
                    </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card text-center border-0 bg-transparent">
                    <div className="card-body">
                        <h5 className="card-title">Prizes & Goodies
</h5>
                        <p className="card-text mb-4">Opportunity to take prizes and swags worth 1Lac</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Why;