import logorgi from "../Assets/logo-rgi.webp" 

const Sponsor = ()=>{

    return(
        <>
        <div className="container text-center spon mt-4 mb-4" id="sponsors">
            <p className="h1 text-center  mt-4">Sponsors</p>

            <div className="row row-cols-2 row-cols-lg-3 g-2 g-lg-3 d-flex align-items-center justify-content-center">
                <div data-aos="flip-left" className="col-6">
                  <img src={logorgi} className="img-fluid simg zoom" />
                </div>
                <div data-aos="flip-left" className="col-6">
                  {/* <img src={logorgi} className="img-fluid simg zoom" /> */}
                </div>
                <div data-aos="flip-left" className="col-6">
                  {/* <img src={logorgi} className="img-fluid simg zoom" /> */}
                </div>
            </div>
                
            </div>
        </>
    )

}
export default Sponsor;