import {Component} from "react";
class ContactPage extends Component {
    render() { 
        return (<>
            <div className="container text-center mt-4">

            <div className="contact-card">
                <p className="h2 mb-4">Contact Us</p>
                <p><strong>Mail: </strong><a className="link-dark" href="mailto:acites.ghrcem@gmail.com">acites.ghrcem@gmail.com</a></p>
                <div className="row row-cols-2 row-cols-lg-3 g-2 g-lg-3 d-flex align-items-center justify-content-center">
                    <div className="col">
                        <div className="card shadow bg-body rounded con-card">
                            <div className="card-body">
                                <h5 className="card-title">Saurav Pandey</h5>
                                <p className="card-subtitle text-muted mb-2">Co-ordinator</p>
                                <a href="tel:+919702531828" className="link" > <i class="fa fa-phone" aria-hidden="true"> </i> </a>
                                <a href="mailto:saurav.pandey.cs@ghriet.raisoni.net" className="link" > <i class="fa fa-envelope" aria-hidden="true"> </i> </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                    <div className="card shadow bg-body rounded con-card">
                            <div className="card-body">
                                <h5 className="card-title">Prasad Choulwar</h5>
                                <p className="card-subtitle text-muted mb-2">Co-ordinator</p>
                                <a href="tel:+917038952913"  className="link" > <i class="fa fa-phone" aria-hidden="true"> </i> </a>
                                <a href="mailto:prasad.choulwar.it@ghriet.raisoni.net" className="link" > <i class="fa fa-envelope" aria-hidden="true"> </i> </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                    <div className="card shadow bg-body rounded con-card">
                            <div className="card-body">
                                <h5 className="card-title">Priya Tank</h5>
                                <p className="card-subtitle text-muted mb-2">Co-ordinator</p>
                                <a href="tel:+919764044940"  className="link" > <i class="fa fa-phone" aria-hidden="true"> </i> </a>
                                <a href="mailto:priya.tank.it@ghriet.raisoni.net" className="link" > <i class="fa fa-envelope" aria-hidden="true"> </i> </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                    <div className="card shadow bg-body rounded con-card">
                            <div className="card-body">
                                <h5 className="card-title">Dhanika Tannu</h5>
                                <p className="card-subtitle text-muted mb-2">Co-ordinator</p>
                                <a href="tel:+919730661829" className="link" > <i class="fa fa-phone" aria-hidden="true"> </i> </a>
                                <a href="mailto:dhanika.tannu.cs@ghriet.raisoni.net"  className="link" > <i class="fa fa-envelope" aria-hidden="true"> </i> </a>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                    <div className="card shadow bg-body rounded con-card">
                            <div className="card-body">
                                <h5 className="card-title">Gaurav Pandey</h5>
                                <p className="card-subtitle text-muted mb-2">Co-ordinator</p>
                                <a href="tel:+917977550525"  className="link" > <i class="fa fa-phone" aria-hidden="true"> </i> </a>
                                <a href="mailto:gaurav.pandey.cs@ghriet.raisoni.net" className="link" > <i class="fa fa-envelope" aria-hidden="true"> </i> </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </div>
            
        </>
        );
    }
}
 
export default ContactPage;