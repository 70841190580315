import logo from "../Assets/logo.png";
import { Link } from "react-router-dom";
import HeadText from "./headtext";
const Header = () =>{

    return(
        
        <>
            <div data-aos="zoom-out-down" className="text-center Header pt-8">

                <img className="img-fluid header-img shadow-lg" src={logo} />
                
                <p className="display-1">CodeVista 2.O </p>
                <p className="display-6">RGI Pune's Coding competition</p>
                <HeadText/>
                {/* <Link to="/register" className="btn cbtn"> Register Now</Link> */}
                {/* https://forms.gle/bx98UmBHSpBT2xv59 */}
                <a href="https://forms.gle/bx98UmBHSpBT2xv59" className="btn cbtn" >Register Now</a>
            </div>
        </>
    )
}
export default Header;