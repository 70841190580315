import {Component} from "react"
class PageNotFound extends Component {
    state = {  } 
    render() { 
        return (<>
            <div className="container NotFound text-center">
                {/* <div className="row row-cols-auto d-flex justify-content-center">
                    <div className="display-1">404</div>
                </div> */}
            </div>
        </>);
    }
}
 
export default PageNotFound;